import * as React from "react"
import { sendFeedback } from "../api";

function BookDemo({ onClose }) {
    const [state, setState] = React.useState({});
    const [message, setMessage] = React.useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        await sendFeedback({
            name: state.name,
            business_name: state.business,
            email: state.email,
            subject: `${state.name} requested a demo`,
            mobile: state.mobile,
            description: "New Book Demo"
        });
        setState({});
        setMessage(true);

        setTimeout(() => {
            setMessage(false);
        }, 3000);
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    }

    function renderMessage() {
        if (message) {
            return (
                <div className="p-4 mb-4 text-sm font-medium text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                    Thank You! We will get back to you shortly.
                </div>
            );
        }
    }

    function renderClose() {
        return (
            <div
                className="absolute w-[28px] right-0 cursor-pointer text-gray-600"
                onClick={onClose}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
            </div>
        );
    }

    return (
        <div>
            <div className="flex justify-center items-center mb-6 relative text-black">
                <h1 className="text-center uppercase">Book Demo</h1>
                {renderClose()}
            </div>
            <form
                onSubmit={handleSubmit}>
                {renderMessage()}
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                    <input onChange={handleInputChange} name="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required value={state.name || ""} />
                </div>

                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Business name</label>
                    <input onChange={handleInputChange} name="business" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required value={state.business || ""} />
                </div>

                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                    <input onChange={handleInputChange} name="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required value={state.email || ""} />
                </div>

                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Mobile number</label>
                    <input onChange={handleInputChange} name="mobile" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required value={state.mobile || ""} />
                </div>

                <button type="submit" className="bg-black text-white font-semibold p-4 block w-full uppercase rounded-md">Submit</button>
            </form>
        </div>
    );
}

export default BookDemo

export const Head = () => <title>Get a free demo of Pickmystylist</title>
